@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/Gilroy/light/Gilroy-Light",
    $font-weight: 300,
    $font-style: normal
);

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path: "../fonts/sofiapro/SofiaPro-Regular",
//     $font-weight: 400,
//     $font-style: normal
// );

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/Gilroy/extrabold/Gilroy-ExtraBold",
    $font-weight: 500,
    $font-style: normal
);

@include lib-typography-all();

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        // @include lib-css(margin-bottom, $indent__xl);
    }
}

.items {
    @include lib-list-reset-styles();
}

.text-gradient {
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background: -webkit-linear-gradient(
        -20deg,
        $color-ba-green,
        $color-ba-orange
    );
    -webkit-background-clip: text;
    background-clip: text;
    white-space: nowrap;
}

.white-text {
    color: #fff;
}

// Deprecated
.default-text-color {
    color: $text__color;
    &.intense {
        color: $text__color__intense;
    }
    &.and-hover {
        &:hover {
            color: inherit;
        }
    }
}

.text-color__default {
    color: $text__color;
    &.intense {
        color: $text__color__intense;
    }
    &.and-hover {
        &:hover {
            color: inherit;
        }
    }
}

.text-color__emphasis {
    color: $text__color__emphasis;
}

.text-color__brand {
    color: $color-ba-green;
}

.text-color__white {
    color: $text__color__white;
}

.color-brand {
    color: $color-ba-green;
}

.link {
    &:hover {
        color: $color-ba-green;
    }
    &.link__body {
        color: $text__color;
        &:hover {
            text-decoration: none;
        }
    }
}

.fw-bold {
    font-weight: bold;
}
