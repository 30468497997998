//
//  Messages
//  _____________________________________________

// Helper function to easier get values related to specific type
@function message($_message-type, $_key) {
    $_map: $message-info-map;

    // find maps adequate to message type
    @if $_message-type == error {
        $_map: $message-error-map;
    }

    @if $_message-type == success {
        $_map: $message-success-map;
    }

    @if $_message-type == warning {
        $_map: $message-warning-map;
    }

    @if $_message-type == notice {
        $_map: $message-notice-map;
    }

    @return map-get($_map, $_key);
}

@mixin lib-message($_message-type: info) {
    @include _lib-message();
    @include _lib-message-style(
        $_message-color: message($_message-type, color),
        $_message-background: message($_message-type, background),
        $_message-link-color: message($_message-type, link__color),
        $_message-link-color-hover: message($_message-type, link__color-hover),
        $_message-link-color-active: message($_message-type, link__color-active),
        $_message-border-color: message($_message-type, border-color)
    );
}

@mixin _lib-message(
    $_message-padding: $message__padding,
    $_message-margin: $message__margin,
    $_message-border-radius: $message__border-radius
) {
    @include lib-css(border-radius, $_message-border-radius);
    @include lib-css(margin, $_message-margin);
    @include lib-css(padding, $_message-padding);
    display: flex;
    align-items: center;
    justify-content: center;
    @include lib-typography(
        $_color: $message__color,
        $_font-size: $message__font-size,
        $_font-family: $message__font-family,
        $_font-weight: $message__font-weight,
        $_font-style: $message__font-style,
        $_line-height: $message__line-height
    );
    @include _lib-message-border(
        $message__border-width,
        $message__border-style
    );
}

@mixin _lib-message-style(
    $_message-background: $message-info__background,
    $_message-color: $message-info__color,
    $_message-link-color: $message-info-link__color,
    $_message-link-color-hover: $message-info-link__color-hover,
    $_message-link-color-active: $message-info-link__color-active,
    $_message-border-color: $message-info__border-color
) {
    @include lib-css(background, $_message-background);
    @include lib-css(border-color, $_message-border-color);
    @include lib-css(color, $_message-color);

    a {
        @include lib-css(color, $_message-link-color);

        &:hover {
            @include lib-css(color, $_message-link-color-hover);
        }

        &:active {
            @include lib-css(color, $_message-link-color-active);
        }
    }
}

@mixin _lib-message-border(
    $_message-border-width: $message__border-width,
    $_message-border-style: $message__border-style
) {
    @if $_message-border-width !=
        0 and
        $_message-border-width !=
        inherit and
        $_message-border-style !=
        inherit
    {
        @include lib-css(border, $_message-border-width $_message-border-style);
    }
}

//  Messages with lateral icon
@mixin lib-message-icon-lateral(
    $_message-type: info,
    $_message-position: right
) {
    @include lib-message($_message-type);
    @include _lib-message-icon-lateral(
        $_message-position,
        message($_message-type, icon),
        message($_message-type, icon__color-lateral),
        message($_message-type, icon__background),
        message($_message-type, icon__top),
        message($_message-type, icon__right),
        message($_message-type, icon__bottom),
        message($_message-type, icon__left)
    );
}

//  Messages with inner icon
@mixin lib-message-icon-inner($_message-type: info) {
    @include lib-message($_message-type);
    @include _lib-message-icon-inner(
        message($_message-type, icon),
        message($_message-type, icon__color-inner),
        message($_message-type, icon__background),
        message($_message-type, icon__top),
        message($_message-type, icon__left),
        message($_message-type, icon__bottom),
        message($_message-type, icon__right)
    );
}

//  Private mixins
@mixin _lib-message-icon-lateral(
    $_message-icon-position,
    $_message-icon,
    $_message-icon-color,
    $_message-icon-background,
    $_message-icon-top,
    $_message-icon-left,
    $_message-icon-bottom,
    $_message-icon-right
) {
    position: relative;

    &:before {
        @include lib-css(background, $_message-icon-background);
        @include lib-css(width, $message-icon__lateral-width);
        content: "";
        display: block;
        height: 100%;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 0;
    }

    & > *:first-child {
        &:before {
            @include lib-css(margin-top, -$message-icon__lateral-arrow-size);
            content: "";
            overflow: hidden;
            position: absolute;
            top: 50%;
        }
    }

    & > *:first-child {
        &:after {
            @include _lib-icon-font(
                $_icon-font-content: $_message-icon,
                $_icon-font: $icon-font,
                $_icon-font-size: $message-icon__font-size,
                $_icon-font-line-height: $message-icon__font-line-height,
                $_icon-font-color: $_message-icon-color,
                $_icon-font-margin: -$message-icon__font-size / 2 0 0,
                $_icon-font-vertical-align: $icon-font__vertical-align
            );
            @include lib-css(bottom, $_message-icon-bottom);
            @include lib-css(left, $_message-icon-left);
            @include lib-css(right, $_message-icon-right);
            @include lib-css(top, $_message-icon-top);
            @include lib-css(width, $message-icon__lateral-width);
            position: absolute;
            text-align: center;
        }
    }

    @include _lib-message-icon-lateral-position(
        $_message-icon-position,
        $_message-icon-background
    );
}

@mixin _lib-message-icon-lateral-position(
    $_message-icon-position,
    $_message-icon-background
) {
    @if $_message-icon-position == right {
        @include lib-css(
            padding-right,
            $message-icon__lateral-width + $indent__s
        );
        &:before {
            right: 0;
            @include _lib-message-icon-lateral-border-radius(
                $_message-icon-position
            );
        }
        & > *:first-child {
            &:before {
                @include lib-arrow(
                    left,
                    $message-icon__lateral-arrow-size,
                    $_message-icon-background
                );
                @include lib-css(right, $message-icon__lateral-width);
            }
            &:after {
                right: 0;
                left: initial;
            }
        }
    } @else if $_message-icon-position == left {
        @include lib-css(
            padding-left,
            $message-icon__lateral-width + $indent__s
        );

        &:before {
            left: 0;
            @include _lib-message-icon-lateral-border-radius(
                $_message-icon-position
            );
        }

        & > *:first-child {
            &:before {
                @include lib-arrow(
                    right,
                    $message-icon__lateral-arrow-size,
                    $_message-icon-background
                );
                @include lib-css(left, $message-icon__lateral-width);
            }
            &:after {
                left: 0;
            }
        }
    }
}

@mixin _lib-message-icon-lateral-border-radius($_message-icon-position) {
    @if $message__border-radius != false and $message__border-width != false {
        @if $_message-icon-position == left {
            @include lib-css(
                border-radius,
                ($message__border-radius - $message__border-width) 0 0
                    ($message__border-radius - $message__border-width)
            );
        }
        @if $_message-icon-position == right {
            @include lib-css(
                border-radius,
                0 ($message__border-radius - $message__border-width)
                    ($message__border-radius - $message__border-width) 0
            );
        }
    }
}

@mixin _lib-message-icon-inner(
    $_message-icon,
    $_message-icon-color,
    $_message-icon-background,
    $_message-icon-top,
    $_message-icon-left,
    $_message-icon-bottom,
    $_message-icon-right
) {
    @include lib-css(padding-left, $message-icon__inner-padding-left);
    position: relative;

    & > *:first-child:before {
        @include _lib-icon-font(
            $_icon-font-content: $_message-icon,
            $_icon-font: $icon-font,
            $_icon-font-size: $message-icon__font-size,
            $_icon-font-line-height: $message-icon__font-line-height,
            $_icon-font-color: $_message-icon-color,
            $_icon-font-margin: 0,
            $_icon-font-vertical-align: $icon-font__vertical-align
        );
        // @include lib-css(bottom, $_message-icon-bottom);
        // @include lib-css(left, $_message-icon-left);
        // @include lib-css(right, $_message-icon-right);
        // @include lib-css(top, $_message-icon-top);
        @include lib-css(width, $message-icon__inner-padding-left);
        // position: absolute;
        text-align: center;
        align-self: center;
    }
}
