//
//  Spacing
//  ---------------------------------------------

$screen__xxs: 320px !default;
$screen__xs: 480px !default;
$screen__s: 768px !default;
$screen__m: 1024px !default;
$screen__l: 1200px !default;
$screen__xl: 1440px !default;

// Magento theme original
// $screen__xxs: 320px !default;
// $screen__xs: 480px !default;
// $screen__s: 640px !default;
// $screen__m: 768px !default;
// $screen__l: 1024px !default;
// $screen__xl: 1440px !default;

//
//  Spacing
//  ---------------------------------------------

$section-space__base: $indent__base * 3 !default; // 60px
$section-space__xl: $indent__base * 6 !default;
$section-space__l: $indent__base * 4.5 !default;
$section-space__s: $indent__base * 1.5 !default;
$section-space__xs: $indent__base * 0.75 !default;

//
//  Grid
//  ---------------------------------------------

$content-grid-gap: 1.5rem;

$grid-list-gap: 2rem;

//
//  Assorted
//  ---------------------------------------------

$header-content-overlap: -6rem;
