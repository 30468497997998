//
//  Colors
//  ---------------------------------------------

$color-ba-green: #3bbf0b;
$color-ba-orange: #faa613;
$color-ba-blue: #5bc0eb;
$color-ss-darkblue: #222;

$color-ss-indigo: #5501e5;
$color-ss-dark-blue: #1752a1;

// Useful for coloring svgs https://codepen.io/sosuke/pen/Pjoqqp
$color-ba-green-filter: invert(49%) sepia(100%) saturate(512%) hue-rotate(62deg)
    brightness(97%) contrast(96%);
//
//  Color nesting

$primary__color: $color-ba-green !default;
$primary__color__dark: darken($primary__color, 7%) !default; // #000
$primary__color__darker: darken($primary__color, 13.5%) !default; // #111
$primary__color__lighter: lighten($primary__color, 29%) !default; // #7d7d7d
$primary__color__light: lighten($primary__color, 45%) !default; // #a6a6a6

$secondary__color: #e8e8e8 !default;
$secondary__color__light: lighten($secondary__color, 5%) !default;

$primary__color__text: #333 !default;
$primary__color__text__darker: #222 !default;
$primary__color__text__lighter: #555 !default;

$page__background-color: #f2f2f2 !default;
$panel__background-color: darken($page__background-color, 6%) !default;
$panel__background-color__dark: #333 !default;
$panel__divider-color: #e0e3ea;
$panel__border-color: #d1d1d1;

$active__color: #ff5501 !default;
$error__color: #e02b27 !default;

$disabled__color: #aaa;

//
//  Colors for contrast calc in buttons (bootstrap)

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $primary__color__text__darker !default;
$yiq-text-light: #fff !default;

//
//  Header
//  ---------------------------------------------

$header__background-color: #121212 !default;
$header-icons-color: #fff !default;
$header-icons-color-hover: $color-ba-green !default;

//
//  Footer
//  ---------------------------------------------

$footer__background-color: #121212 !default;

//
//  Component
//  ---------------------------------------------

$component__background-color__dark: #222 !default;
