#gtag-consent-banner {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid $panel__divider-color;
    box-shadow: $panel-shadow;
    padding: 1.5rem;
    z-index: 9999;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1rem;

    p {
        margin: 0;
        font-size: 0.9em;
    }

    .button-group {
        display: grid;
        grid-template-columns: auto auto;
        gap: 1rem;

        .button {
            width: auto;
        }
    }

    @include max-screen($screen__m) {
        grid-template-columns: auto;

        .button-group {
            width: 100%;

            .button {
                width: 100%;
            }
        }
    }
}