// Gradients
.black_indigo {
    @include gradient-background(45deg, #000, #5501e5);
}

.black_black {
    @include gradient-background(45deg, #000, #000);
}

.green_blue {
    @include gradient-background(45deg, #3bbf0b, #81c3f0);
}