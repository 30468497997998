@charset "UTF-8";
/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */
.ms-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: auto !important;
    top: auto !important;
}

.ms-parent {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.ms-choice {
    display: block;
    @include lib-form-element-input($_type: select);
    height: unset;
    padding: 1rem 2.5rem 1rem 1rem;
    text-align: left;
    overflow: hidden;
    border-radius: $form-element-input__border-radius
        $form-element-input__border-radius 0 0;
    cursor: pointer;
    &:focus,
    &:active {
        border-color: $form-element-input__border-color;
    }
    &.disabled {
        background-color: #f4f4f4;
        background-image: none;
        border: 1px solid #ddd;
        cursor: default;
    }
    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        &.placeholder {
            font-weight: normal;
            color: #999;
        }
    }
    > div.icon-close {
        position: absolute;
        top: 0px;
        right: 16px;
        height: 100%;
        width: 16px;
        &:before {
            content: "×";
            color: #888;
            font-weight: bold;
            position: absolute;
            top: 50%;
            margin-top: -14px;
        }
        &:hover:before {
            color: #333;
        }
    }
    > div.icon-caret {
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        right: 8px;
        margin-top: -2px;
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        &.open {
            border-color: transparent transparent #888 transparent;
            border-width: 0 4px 5px 4px;
        }
    }
}

.ms-drop {
    width: auto;
    min-width: 100%;
    overflow: hidden;
    display: none;
    margin-top: -1px;
    padding: 0;
    position: absolute;
    z-index: 1000;
    background: #fff;
    //color: #000;
    border: 1px solid $form-element-input__border-color;
    border-radius: 0 0 $form-element-input__border-radius
        $form-element-input__border-radius;
    &.bottom {
        top: 100%;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    }
    &.top {
        bottom: 100%;
        box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
    }
    ul {
        list-style: none;
        overflow: auto;
        margin: 0;
        padding: 0.5rem 0;
        > li {
            background-image: none;
            margin-bottom: 0;
            &.multiple {
                display: block;
                float: left;
            }
            &.group {
                clear: both;
            }
            &.multiple label {
                width: 100%;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.hide-radio {
                padding: 0;
                &:focus,
                &:hover {
                    background-color: #f8f9fa;
                }
                &.selected {
                    color: #fff;
                    background-color: #007bff;
                }
                label {
                    margin-bottom: 0;
                    padding: 5px 8px;
                }
                input {
                    display: none;
                }
            }
            label {
                position: relative;
                padding: 1rem 1.2rem;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                width: 100%;
                margin-bottom: 0;
                font-weight: bold;
                white-space: nowrap;
                cursor: pointer;
                &.optgroup {
                    font-weight: bold;
                }
                > input[type="checkbox"] {
                    -webkit-appearance: none;
                    background-color: #fafafa;
                    border: 1px solid $form-element-input__border-color;
                    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                    //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    padding: 6px;
                    margin: 0;
                    border-radius: 3px;
                    display: inline-block;
                    position: relative;
                    &:checked {
                        background-color: $color-ba-green;
                        border: 1px solid $color-ba-green;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
                            inset 15px 10px -12px rgba(255, 255, 255, 0.1);
                        color: #99a1a7;
                        &:after {
                            // content: "\2714";
                            // font-size: 11px;
                            // position: absolute;
                            // top: 0px;
                            // left: 2px;
                            // color: $primary__color__dark;
                        }
                    }
                    &:active,
                    &:checked:active {
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                            inset 0px 1px 3px rgba(0, 0, 0, 0.1);
                    }
                }
                > span {
                    line-height: normal;
                    height: 1rem;
                    flex: 1;
                }
            }
            .option-level-1 label {
                padding-left: 28px;
            }
            .disabled {
                font-weight: normal !important;
                opacity: 0.35;
                filter: Alpha(Opacity=35);
                cursor: default;
            }
        }
    }
    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        margin-top: 0.3rem;
        margin-left: -1.25rem;
    }
    .ms-no-results {
        display: none;
    }
}

.ms-search {
    display: inline-block;
    margin: 0;
    min-height: 26px;
    padding: 2px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 10000;
    box-sizing: border-box;
    input {
        width: 100%;
        height: auto !important;
        min-height: 24px;
        padding: 0 5px;
        margin: 0;
        outline: 0;
        font-family: sans-serif;
        border: 1px solid #aaa;
        border-radius: 5px;
        box-shadow: none;
    }
}
