.form-label {
  display: block;
  margin-bottom: 2px;
}
input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"],
textarea,
select {
  &.form-input {
    height: unset;
    padding: 14px 10px 11px;
    line-height: normal;
    margin-bottom: $indent__base;
  }
  height: unset;
  padding: 1rem;
}

#all-products-list {
  input[type="text"],
  input[type="password"],
  input[type="url"],
  input[type="tel"],
  input[type="search"],
  input[type="number"],
  input[type="datetime"],
  input[type="email"],
  textarea,
  select {
    height: unset;
    padding: 1rem;
  }
}
