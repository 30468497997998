@mixin curved-edge(
    $color: transparent,
    $height: 90px,
    $position: top,
    $margin: 0
) {
    background-color: $color;
    width: 120%;
    margin: $margin;
    height: $height;
    @if $position == "top" {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
    } @else {
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }
}
