.step {
    width: 6rem;
    height: 6rem;
    font-size: 3rem;
    font-weight: $font-weight__bold;
    color: #fff;
    border-radius: 9999px;
    margin: 1rem auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    &.s {
        &-1 {
            background-color: #565499;
        }
        &-2 {
            background-color: #f3901d;
        }
        &-3 {
            background-color: #5bc0eb;
        }
        &-4 {
            background-color: #8cc63f;
        }
    }
}

.d-table {
    border-collapse: collapse;
}

.d-table-cell {
    padding: 0.5rem;
    border: 1px solid #999;
    &.table-header {
        color: #fff;
        background-color: $component__background-color__dark;
        border-color: $component__background-color__dark;
        font-weight: $font-weight__bold;
    }
    &.table-row-title {
        font-weight: $font-weight__bold;
    }
}
