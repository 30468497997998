//
//  Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal-title__color: $text__color !default;
$modal-title__border: 1px solid $color-gray-light5 !default;

$modal-popup-title__font-size: 26px !default;
$modal-popup-title-mobile__font-size: $font-size__base !default;

$modal-slide__first__indent-left: 44px !default;
$modal-slide-mobile__background-color: $color-gray-light01 !default;
$modal-overlay__background-color: rgba(0, 0, 0, 0.2) !default;

$modal-action-close__color: $primary__color !default;
$modal-action-close__font-size: 32px !default;
$modal-action-close__hover__color: darken($primary__color, 10%) !default;

$modal-slide-action-close__padding: $modal-slide-header__padding-vertical - 1rem
  $modal-popup__padding - 1rem !default;

//
//  Common
//  _____________________________________________

.modal-custom,
.modal-popup,
.modal-slide {
  .action-close {
    @include lib-button-reset();
    @include lib-button-icon(
      $icon-remove,
      $_icon-font-color: $color-gray80,
      $_icon-font-size: $modal-action-close__font-size,
      $_icon-font-line-height: $modal-action-close__font-size,
      $_icon-font-text-hide: true
    );
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      &:before {
        color: $modal-action-close__hover__color;
      }
    }
  }
}

.modal-custom {
  .action-close {
    @include lib-css(margin, $indent__m);
  }
}

.modal-popup {
  pointer-events: none;

  .modal-title {
    @include lib-css(border-bottom, $modal-title__border);
    @include lib-css(font-weight, $font-weight__light);
    @include lib-css(padding-bottom, $indent__s);
    font-size: $modal-popup-title__font-size;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word;
  }

  .action-close {
    padding: $indent__s;
  }
}

.modal-slide {
  .action-close {
    padding: $modal-slide-action-close__padding;
  }

  .page-main-actions {
    margin-bottom: calc(
      #{$modal-slide-header__padding-vertical} - #{$indent__l / 2}
    );
    margin-top: $modal-slide-header__padding-vertical;
  }
}

.modals-overlay {
  @include lib-css(background-color, $modal-overlay__background-color);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

body {
  &._has-modal-custom {
    .modal-custom-overlay {
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: $overlay__z-index;
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .custom-slide {
    @include lib-modal();
    @include lib-modal-slide();

    &._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .modal-inner-wrap {
      @include lib-css(background-color, $modal-slide-mobile__background-color);
      box-sizing: border-box;
      height: auto;
      min-height: 100%;
    }
  }

  body {
    &._has-modal-custom {
      height: 100vh;
      overflow: hidden;
      width: 100vw;

      .modal-custom-overlay {
        @include lib-css(background-color, $modal-overlay__background-color);
      }
    }
  }
}

@include max-screen($screen__m) {
  .modal-popup {
    &.modal-slide {
      .modal-inner-wrap[class] {
        @include lib-css(
          background-color,
          $modal-slide-mobile__background-color
        );
      }

      &._inner-scroll {
        &._show {
          -webkit-overflow-scrolling: touch;
          overflow-y: auto;
        }

        .modal-inner-wrap {
          height: auto;
          min-height: 100%;
        }
      }
    }

    .modal-title {
      @include lib-css(font-size, $modal-popup-title-mobile__font-size);
      @include lib-css(font-weight, $font-weight__bold);
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .modal-popup {
    &.modal-slide {
      .modal-footer {
        @include lib-css(border-top, $modal-title__border);
        text-align: right;
      }
    }
  }
}
