$widgets-indent__bottom: $indent__base !default;

.block-static-block,
.block-cms-link {
    &.widget {
        @include lib-css(margin-bottom, $widgets-indent__bottom);
        .links & {
            margin-bottom: 0;
        }
    }
}

.block-cms-link-inline {
    &.widget {
        margin: 0;
    }
}

h1,
h2,
h3 {
    .block-cms-link {
        color: $heading__color__base;
        > a:hover {
            color: $heading__color__base;
        }
    }
}
