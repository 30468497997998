section {
    margin-top: 5rem;
    margin-bottom: 8rem;
    &.half {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    &.inset {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 8rem;
        padding-bottom: 8rem;
        &.half {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }
    @include max-screen($screen__m) {
        margin-top: 5rem;
        margin-bottom: 5rem;
        &.inset {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
}

.grid-item-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: $grid-list-gap;
    padding: 0;
    list-style: none;
    @include min-screen($screen__xl) {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
    @include max-screen($screen__m) {
        gap: $grid-list-gap / 2;
    }
    &.gap-x2 {
        gap: $grid-list-gap * 2;
    }
    &.item-size {
        &__sm {
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        }
        &__sm-fixed {
            grid-template-columns: repeat(auto-fill, minmax(120px, 180px));
            justify-content: center;
        }
        &__md {
            grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
        }
        &__md-fixed {
            grid-template-columns: repeat(auto-fill, minmax(240px, 300px));
            justify-content: center;
        }
        &__lg {
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }
        &__lg-fixed {
            grid-template-columns: repeat(auto-fill, minmax(320px, 400px));
            justify-content: center;
        }
        &__xl {
            grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
            @include max-screen($screen__s) {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            }
        }
        &__full {
            grid-template-columns: 1fr;
        }
    }
}

.grid-anchor-right {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    gap: 1rem;
    // .right {
    //     text-align: right;
    // }
}

.icon-content-stack {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 2rem;
    color: $color-ss-darkblue;
    .title {
        display: block;
        color: inherit;
        font-size: 1.8rem;
        font-weight: 400;
        padding-right: 3rem;
    }
    .subtitle {
        color: inherit;
        font-size: 1.6rem;
        font-weight: 300;
    }
    &.white {
        color: #fff;
    }
}

.content-image-stack {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 3rem;
    @include max-screen($screen__l) {
        grid-template-columns: 1fr 1fr;
    }
    @include max-screen($screen__m) {
        grid-template-columns: auto;
        gap: 0;
    }
    &.flip {
        grid-template-columns: auto 1fr;
    }
    .content {
        padding: 6rem 3rem;
        &.grid {
            display: grid;
            grid-template-rows: auto;
            gap: $content-grid-gap;
            .button {
                justify-self: start;
            }
        }
        @include max-screen($screen__m) {
            order: 2;
            padding: 3rem;
        }
        .title {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .image {
        font-size: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @include max-screen($screen__m) {
            order: 1;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
