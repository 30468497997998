//
//  Category page
//  ---------------------------------------------

.category-view-top {
    margin-bottom: 0;
}

.catalog-product-view {
    .price-wrapper {
        .price-currency {
            //color: $color-ba-orange;
            .price {
            }
        }
    }
}

.category-short-description {
    text-align: center;
}

.category-description {
    :first-child {
        margin-top: 0;
    }
}

.product-details {
    .price-box {
        .price {
            font-size: 1em;
            font-weight: bold;
            white-space: nowrap;
        }
    }
    .estimated-delivery-date {
        font-weight: bold;
        color: $color-ba-blue;
    }
}

.product-description {
    //margin-bottom: $indent__m;
    padding: 0;
    ul {
        list-style: none;
        text-align: left;
        padding: 0 0 0 $indent__s;
        li {
            margin: 0;
            padding: 0;
            &::before {
                content: "\2714";
                color: $primary__color;
                font-weight: bold;
                display: inline-block;
                width: 1.2em;
                margin-left: -$indent__s;
            }
            &:first-child {
                padding-top: 0;
            }
        }
    }
}

.product-image-container {
    font-size: 0;
    overflow: hidden;
    background-color: #eee;
    width: 100%;
    height: 240px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.installation-services-content-container {
    text-align: left;
    display: grid;
    gap: 3rem;
    grid-template-columns: auto 20%;
    align-items: center;
    .badges {
        display: grid;
        gap: 0.5rem;
    }
    .badge-row {
        display: flex;
        align-items: center;
        gap: 3rem;
        justify-content: space-between;
        img {
            justify-self: center;
        }
    }
    @include max-screen($screen__l) {
        grid-template-columns: auto 50%;
    }
    @include max-screen($screen__m) {
        grid-template-columns: unset;
    }
}

//
//  Product page
//  ---------------------------------------------

.order-form {
    @include max-screen($screen__m) {
        margin-bottom: 3rem;
    }
}

.overview {
    height: 100%;
}
.sticky-summary {
    position: sticky;
    position: -webkit-sticky;
    top: $indent__xl;
}

.product-usps {
    margin-bottom: 0;
    ul {
        list-style: none;
        margin-bottom: 0;
        padding: $indent__xs 0 0 0;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-wrap: wrap;
        li {
            margin: 0;
            padding: $indent__xs $indent__base 0 0;
            width: 50%;
            font-weight: bold;
            font-size: 0.95em;
            &::before {
                content: "\2714";
                color: $primary__color;
                font-weight: bold;
                display: inline-block;
                width: 1.2em;
            }
        }
    }
}

.label-note {
    font-size: $font-size__s;
    margin-left: 0.2em;
    font-weight: $font-weight__regular;
}

@mixin tag() {
    position: absolute;
    top: 1.5rem;
    left: 2.5rem;
    padding: 4px 6px;
    background-color: $color-ba-green;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 3px;
}
.product-options-wrapper .fieldset div {
    .option-container {
        &.tag {
            &_recommended::after {
                @include tag();
                content: "Recommended";
            }
            &_new::after {
                @include tag();
                content: "New";
            }
            &_popular::after {
                @include tag();
                content: "Popular";
            }
            &_sale::after {
                @include tag();
                content: "Sale";
            }
        }
    }
}

.products-related {
    display: grid;
    gap: 1rem;
    padding: 0;
    margin-bottom: 0;
    .product-item-info {
        display: grid;
        grid-template-columns: auto auto 1fr auto;
        align-items: center;
        gap: 1rem;
        .product-item-name {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .price-box {
            text-align: right;
        }
        .field.choice.related {
            padding-right: 2rem;
        }
        @include max-screen($screen__m) {
            display: block;
        }
    }
}

.product-options-bottom {
    .estimated-delivery-placeholder {
        margin-bottom: 1.5rem;
    }
    .estimated-delivery {
        margin-bottom: 1.5rem;
    }
    .estimated-delivery-date {
        font-weight: bold;
        color: $color-ba-blue;
    }
    .price-box {
        .price-wrapper {
            .price-currency {
                font-size: $font-size__l;
                .price {
                    font-size: $font-size__l;
                }
                .tax-info {
                    font-size: $font-size__l;
                    font-weight: 300;
                    // color: #666;
                    margin-left: 0.8rem;
                }
            }
        }
    }
    .button.tocart {
        &.buy {
            border-color: $color-ba-orange;
            background-color: $color-ba-orange;
            &:hover {
                border-color: darken($color-ba-orange, 7%);
                background-color: darken($color-ba-orange, 7%);
            }
        }
        &.quote {
            border-color: $color-ba-green;
            background-color: $color-ba-green;
            &:hover {
                border-color: darken($color-ba-green, 7%);
                background-color: darken($color-ba-green, 7%);
            }
        }
    }
}

.discount-info {
    margin-bottom: 1.5rem;
}

.sellable-badge {
    color: $color-ba-green;
    padding: 0.8rem 1.2rem;
    background: adjust-color($color-ba-green, $alpha: -0.85);
    border-radius: 0.5rem;
    line-height: 1;
    &.buy {
        color: $color-ba-orange;
        background: adjust-color($color-ba-orange, $alpha: -0.85);
    }
}

//
//  Product
//  ---------------------------------------------

.intro-section {
    margin-top: $header-content-overlap;
    position: relative;
    z-index: 4;
    @media (max-width: $screen__m) {
        margin-top: 0;
    }
}

.info-tab-content {
    min-height: 28rem;
    @include max-screen($screen__m) {
        min-height: unset;
    }
}

.overview-links-header {
    margin-bottom: 0;
    display: block;
    border-bottom: 1px solid $color-ba-green;
    padding-bottom: 0.5rem;
}

.overview-links {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    // justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    li {
            margin-bottom: 0;
        }
    a {
        display: block;
        text-decoration: none;
        font-weight: bold;
        padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        &:hover {
            text-decoration: underline;
        }
    }
}
#image-gallery {
    .img-description {
        position: absolute;
        bottom: 28px;
        left: 0;
        color: #fff;
        text-shadow: 1px 1px 2px #000;
        padding: 0.6rem 1rem 0.6rem 2rem;
        font-size: 14px;
        background-color: #000000C0;
        border-radius: 0 6px 6px 0;
        margin-right: 28px;
    }
}
.long-content {

    h1,
    h2,
    h3,
    h4,
    h5 {
        &:first-of-type {
            margin-top: 0;
        }
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}
//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-usps {
        ul {
            li {
                width: 100%;
            }
        }
    }
}
