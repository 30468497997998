.g-icon {
    width: $icon-size__l;
    height: $icon-size__l;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    &.center {
        margin: 0 auto;
    }
    &.size__xl {
        width: $icon-size__xl;
        height: $icon-size__xl;
    }
    &.size__m {
        width: $icon-size__m;
        height: $icon-size__m;
    }
    &.size__s {
        width: $icon-size__s;
        height: $icon-size__s;
    }
    &.size__xs {
        width: $icon-size__xs;
        height: $icon-size__xs;
    }
}
