.halftone {
    background: white;
    position: relative;
    filter: contrast(25000%);
    overflow: hidden;
    transform: translateZ(0); /* force a HW accelerated layer */
    &::after {
        content: "";
        position: absolute;
        top: -100%;
        left: -100%;
        right: -100%;
        bottom: -100%;
        background-blend-mode: multiply;
        background: radial-gradient(8px 8px, cyan, white),
            radial-gradient(8px 8px, magenta, white),
            radial-gradient(8px 8px, yellow, white);
        background-size: 8px 8px;
        background-position: 0 -3px, -2px 0, 2px 0;
        mix-blend-mode: screen;
        pointer-events: none;
        transform: rotate(11.25deg);
        transition: 1s ease transform;
        z-index: 1;
        animation: 12s animation-overlay infinite alternate;
    }
    > * {
        filter: brightness(0.5) blur(4px);
        animation: 10s animation-filter infinite alternate;
    }
}

@keyframes animation-overlay {
    0% {
        transform: rotate(-65.25deg) scale(10);
    }

    100% {
        transform: rotate(-11.25deg) scale(2);
    }
}

@keyframes animation-filter {
    0% {
        filter: brightness(0.5) blur(40px);
    }

    100% {
        filter: brightness(0.5) blur(4px);
    }
}

.test-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
    gap: $grid-list-gap;
    padding: 0;
    @include max-screen($screen__m) {
        gap: $grid-list-gap / 2;
    }
}

// Text
$stroke: #0d1b1e;
$stroke-light: #fff;
$shadow: $color-ba-green;
$offset: 4px;
$size: 8rem;
$halftone: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAHElEQVQYV2NUVFT8f//+fUYGBgYGMAEDcA5IBQCKJQVmn76DhAAAAABJRU5ErkJggg==");

@mixin stroke-width($property) {
    -webkit-text-stroke-width: $property;
    -moz-text-stroke-width: $property;
}

@mixin stroke-color($property) {
    -webkit-text-stroke-color: $property;
    -moz-text-stroke-color: $property;
}

@mixin background-clip($property) {
    -webkit-background-clip: $property;
    -moz-background-clip: $property;
    background-clip: $property;
}

@mixin fill-color($property) {
    -webkit-text-fill-color: $property;
    -moz-text-fill-color: $property;
}

.ht-text {
    font-size: $size;
    letter-spacing: 4px;
    margin: 0;
    font-weight: 700;
    text-align: center;

    &--stroke {
        @include stroke-width(2px);
        @include stroke-color($stroke);
        color: transparent;
    }

    &--shadow {
        text-shadow: $offset $offset $shadow;
    }

    &--stroke-shadow {
        color: #222;
        text-shadow: -2px 0 $stroke-light, 0 -2px $stroke-light,
            2px 0 $stroke-light, 0 2px $stroke-light, 2px 2px $stroke-light,
            -2px -2px $stroke-light, -2px 2px $stroke-light,
            2px -2px $stroke-light, $offset + 2 $offset + 2 $shadow;
    }

    &--halftone {
        position: relative;

        &:after {
            background: $halftone repeat;
            @include stroke-width(0);
            @include background-clip(text);
            @include fill-color(transparent);
            font-size: $size;
            letter-spacing: 4px;
            left: calc(50% + 4px);
            top: calc(50% + 4px);
            content: attr(data-text);
            z-index: -1;
            position: absolute;
            transform: translate(-50%, -50%);
            white-space: nowrap;
        }

        &-color {
            &:after {
                background-color: $shadow;
            }
        }
    }
}
