.n2-panel {
    @include panel($panel-background-color);
    //margin-bottom: $indent__base * 2;
    &.shadow {
        box-shadow: $panel-shadow;
    }
    &.border {
        border: 1px solid $panel__border-color;
    }
    &.no-radius {
        border-radius: 0;
    }
    &.no-background {
        background-color: unset;
    }
    &.dark {
        color: #fff;
        background-color: $panel-background-color-dark;
        h2,
        h3,
        h4 {
            color: #fff;
        }
        .link {
            color: inherit;
        }
    }
    &.link {
        color: $text__color;
        text-decoration: none;
        transition: $button-transition;
        .panel-link-text {
            color: $color-ss-darkblue;
            font-weight: 400;
            &::after {
                content: "›";
                display: inline-block;
                margin-left: 0.5rem;
                transition: $button-transition;
            }
        }
        &:hover {
            color: $text__color;
            cursor: pointer;
            &.shadow {
                box-shadow: $panel-shadow-hover;
                transform: $panel-shadow-hover-transform;
            }
            .panel-link-text {
                &::after {
                    transform: translateX(0.5rem);
                }
            }
        }
    }
    &.center-panel {
        margin-left: auto;
        margin-right: auto;
    }
    &.w-60 {
        width: 60%;
        @include max-screen($screen__l) {
            width: 80%;
        }
        @include max-screen($screen__m) {
            width: 100%;
        }
    }
    &.w-40 {
        width: 40%;
        @include max-screen($screen__l) {
            width: 60%;
        }
        @include max-screen($screen__m) {
            width: 100%;
        }
    }
    &.grid {
        display: grid;
        grid-template-rows: auto;
        gap: $panel-content-grid-gap;
        &.a-a-fr {
            grid-template-rows: auto auto 1fr;
        }
        &.a-fr-a {
            grid-template-rows: auto 1fr auto;
        }
        &.a-a-fr-a {
            grid-template-rows: auto auto 1fr auto;
        }
        &.c_a-fr {
            grid-template-columns: auto 1fr;
        }
    }
    &.center-content {
        justify-content: center;
        text-align: center;
    }
    &.pad {
        padding: 3rem;
    }
    &.allow-overflow {
        overflow: visible;
    }
    &.sticky {
        position: sticky;
        position: -webkit-sticky;
    }
    .top-radius-container {
        border-radius: $panel-border-radius $panel-border-radius 0 0;
        overflow: hidden;
    }
    .image-container {
        position: relative;
        font-size: 0;
        overflow: hidden;
        background-color: #eee;
        &.product {
            width: 100%;
            height: 240px;
        }
        &.product-gallery {
            width: 100%;
            height: 413px;
        }
        &.category {
            width: 100%;
            height: 320px;
        }
        &.large-panel {
            width: 100%;
            height: 420px;
            @media(max-width: 767px) {
                height: 300px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content {
        padding: $panel-content-padding;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }
    }
    .actions {
        &.product,
        &.category {
            width: 100%;
            padding: 0 $indent__base * 2 $indent__base;
            .button {
                width: 100%;
                display: block;
            }
        }
    }

    a {
        img {
            width: 100%;
            transition: all 0.2s ease;
            transform: scale(1);
        }
        &:hover {
            img {
                transform: scale(1.04);
            }
        }
    }
}

.panel-link {
    transition: $link-transition;
    border-radius: $panel-border-radius;
    background: transparent;
    border: 1px solid transparent;
    &:hover {
        text-decoration: none;
        //background-color: rgba(27, 187, 217, 0.1);
    }
    &.panel-link__white {
        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
            border-color: rgba(255, 255, 255, 0.2);
            box-shadow: $panel-shadow-hover;
            transform: $panel-shadow-hover-transform;
        }
    }
}
