//
//  Layout & Display
//  ---------------------------------------------

// Sizing

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

// Padding and Margin

.no-padding {
    padding: 0 !important;
}
.no-padding-bottom {
    padding-bottom: 0 !important;
}
.no-padding-top {
    padding-top: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.no-top-margin {
    margin-top: 0 !important;
}
.no-bottom-margin {
    margin-bottom: 0 !important;
}

$sizes-map: (
    xs: $section-space__xs,
    s: $section-space__s,
    base: $section-space__base,
    l: $section-space__l,
    xl: $section-space__xl,
);
$attributes: padding, margin;
$directions: top, bottom, left, right;

@each $attribute in $attributes {
    @each $direction in $directions {
        .section-#{$attribute}__#{$direction} {
            @each $size, $value in $sizes-map {
                @if $size == base {
                    #{$attribute}-#{$direction}: #{$value};
                } @else {
                    &.section-size__#{$size} {
                        #{$attribute}-#{$direction}: #{$value};
                    }
                }
            }
        }
    }
}

@each $direction in $directions {
    .section-overlap__#{$direction} {
        @each $size, $value in $sizes-map {
            @if $size == base {
                margin-#{$direction}: -#{$value};
            } @else {
                &.section-size__#{$size} {
                    margin-#{$direction}: -#{$value};
                }
            }
        }
    }
}

// Display

.d-table {
    display: table;
    &.c1f-c2a {
        .d-table-cell:first-child {
            width: 100%;
        }
        .d-table-cell:last-child {
            @include min-screen($screen__m) {
                white-space: nowrap;
            }
        }
    }
}

.d-table-row {
    display: table-row;
}

.d-table-cell {
    display: table-cell;
}

.nodisplay,
.no-display {
    display: none;
}

.nodisplay__force {
    display: none !important;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

// Position

.p-relative {
    position: relative;
}

.position-absolute-fill,
.absolute-fill {
    @include position-absolute-fill();
}

//
//  Typography
//  ---------------------------------------------

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.fs-0 {
    font-size: 0;
}

@include max-screen($screen__m) {
    .nowrap {
        white-space: nowrap;
    }
}

//
//  Components
//  ---------------------------------------------

.no-list-style {
    list-style: none none;
}

//
// Assorted
//  ---------------------------------------------

.pointer {
    cursor: pointer;
}
