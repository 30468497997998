// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
// prettier-ignore
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
// prettier-ignore
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// Variables pulled from:
// styles/vendor/magento-ui/variables/_responsive.scss
// styles/vendor/magento-ui/variables/_global.scss

$grid-breakpoints: (
    xs: 0,
    sm: $screen__s,
    md: $screen__m,
    lg: $screen__l,
    xl: $screen__xl,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: $indent__base * 2 !default;

$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
    ((21 9), (16 9), (4 3), (1 1)),
    $embed-responsive-aspect-ratios
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// prettier-ignore
$container-max-widths: (
  sm: $screen__s -
    (
      $grid-gutter-width * 2
    ),
  md: $screen__m -
    (
      $grid-gutter-width * 2
    ),
  lg: $screen__l -
    (
      $grid-gutter-width * 2
    ),
  xl: $screen__xl -
    (
      $grid-gutter-width * 2
    )
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
    inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;

// Printing

$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "lg") !default;
