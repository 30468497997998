//
//  Footer
//  ---------------------------------------------

footer {
    color: #fff;
    .link {
        color: inherit;
        display: block;
        padding: $indent__base * 0.5 $indent__base * 0.5;
        transition: $link-transition;
        line-height: normal;
        margin-bottom: $indent__s;
        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }
    .contact-links {
        text-transform: uppercase;
        font-weight: bold;
    }
    .copyright {
        color: inherit;
        opacity: 0.6;
    }
}

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
    position: relative;
    z-index: 2;
    // &:before {
    //     background: inherit;
    //     content: "";
    //     display: block;
    //     height: 50%;
    //     left: 0;
    //     position: absolute;
    //     right: 0;
    //     z-index: -1;
    //     -webkit-backface-visibility: hidden;
    //     top: 0;
    //     transform: skewY(1.5deg);
    //     transform-origin: 100% 0;
    // }
}

.footer-container {
    position: relative;
    .curve-container {
        top: -($curve-adornment-height);
    }
    .footer-curve {
        position: absolute;
        @include curved-edge($footer__background-color);
    }
    .footer {
        padding-bottom: $curve-adornment-height;
        h2,
        h3,
        h4 {
            color: #fff;
        }
        a:not(.button) {
            line-height: 1.8;
        }
        .copyright {
            text-align: center;
            margin: 2rem;
        }
        @media (max-width: $screen__m) {
            h2 {
                text-align: center;
            }
        }
    }
}

// TODO: @extend this
.contact-list {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: $grid-list-gap * 2;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media (max-width: $screen__m) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        gap: $grid-list-gap * 1.5;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .contact-item {
        display: grid;
        justify-content: center;
        .icon-content-stack {
            margin-bottom: 3rem;
        }
        .button {
            color: #fff;
            &:hover {
                color: $color-ba-green;
            }
        }
    }
    .contact-divider {
        background-color: rgba(255, 255, 255, 0.2);
        width: 1px;
        @media (max-width: $screen__m) {
            display: none;
        }
    }
}

.footer-link-grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3rem;
    .footer-section {
        display: grid;
        grid-template-rows: 4rem auto;
        min-width: 18rem;
    }
    .footer-section_title {
        grid-row: 1;
        margin-top: 0;
    }
    .footer-section_content {
        grid-row: 2;
    }
    a {
        display: block;
    }
    @media (max-width: $screen__m) {
        display: grid;
        grid-template-rows: auto;
        justify-content: center;
        .footer-section {
            display: grid;
            grid-template-rows: auto auto;
            min-width: unset;
        }
    }
}
