#contact-wrapper {
    position: relative;
}
#contact-form {
    opacity: 1;
    transition: opacity 0.3s ease;
    &.submitted {
        opacity: 0;
    }
    .field-recaptcha {
        margin-bottom: 2rem;
    }
}

#contact-submit-btn {
    &.submitted {
        background-color: $disabled__color;
        border-color: $disabled__color;
    }
}

#contact-message {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    transition-delay: 0.5s;
    pointer-events: none;
    &.submitted {
        opacity: 1;
    }
}
