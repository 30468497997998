@mixin panel(
  $background-color: transparent,
  $width: auto,
  $height: auto,
  $margin: 0,
  $padding: 0,
  $border-radius: $panel-border-radius
) {
  background-color: $background-color;
  width: $width;
  height: $height;
  margin: $margin;
  padding: $padding;
  border-radius: $border-radius;
  overflow: hidden;
}
