html {
    scroll-behavior: smooth;
}

body {
    @include lib-css(background-color, $page__background-color);
}

.background-white {
    background-color: #fff;
}

.background-light {
    background-color: $color-gray99;
}

// .background-light-blue {
//     background-color: $color-ss-light-blue2;
// }

.background-dark {
    background-color: $color-gray11;
}

.background-circle-position {
    position: absolute;
    top: -60%;
    z-index: -1;
    &.left {
        left: 0;
        width: 45%;
        overflow: hidden;
    }
    &.right {
        right: 0;
        width: 45%;
        overflow: hidden;
    }
    .circle-container {
        width: 200%;
        &.left {
            margin-left: -100%;
        }
    }
}

.background-circle-pink {
    background-image: url(../images/icons/bckg-circle-pink.svg);
}

// .with-divider {
//     &::after {
//         content: "";
//         display: block;
//         width: $indent__base * 3;
//         border-bottom: 3px solid $color-ba-orange;
//         margin: $indent__base auto $indent__base * 1.5;
//     }
//     &.with-divider__small-divider {
//         width: $indent__base * 2;
//     }
//     &.with-divider__left::after {
//         margin-left: 0;
//         margin-right: 0;
//     }
//     &.dark::after {
//         border-bottom-color: $text__color__intense;
//     }
//     &.with-divider__white::after {
//         border-bottom-color: #fff;
//     }
// }

.img-stack {
    position: relative;
    .img-container {
        position: absolute;
        overflow: hidden;
        font-size: 0;
        &.top {
            top: 0;
        }
        &.bottom {
            bottom: 0;
        }
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        &.full {
            width: 100%;
        }
        &.large {
            width: 90%;
        }
        &.small {
            width: 50%;
        }
    }
}

.img-container {
    &.rounded {
        border-radius: $panel-border-radius;
        overflow: hidden;
    }
    &.circle {
        border-radius: 999px;
        overflow: hidden;
    }
    &.border {
        border: $panel-border-radius solid #fff;
        border-radius: $panel-border-radius * 2;
        > img {
            border-radius: $panel-border-radius;
        }
    }
    &.shadow {
        box-shadow: $panel-shadow;
    }
    .icon {
        width: $indent__base * 4;
        height: $indent__base * 4;
        display: flex;
        align-items: center;
        justify-content: center;
        &.icon__m {
            width: $indent__base * 3;
            height: $indent__base * 3;
        }
        &.icon__s {
            width: $indent__base * 2;
            height: $indent__base * 2;
        }
        &.icon__xs {
            width: $indent__base * 1.2;
            height: $indent__base * 1.2;
        }
    }
}

a.img-container {
    transition: $link-transition;
    .icon {
        opacity: 0.8;
    }
    &:hover {
        .icon {
            opacity: 1;
        }
    }
}

// Lazyloading image blur effect

.ls-blur-up-img {
    filter: blur(10px);
    transition: filter 400ms;
    &.lazyloaded {
        filter: blur(0);
    }
}
