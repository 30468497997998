.load.indicator {
    @include lib-loader();
    position: absolute;

    > span {
        display: none;
    }
}

.loading-mask {
    @include lib-loading-mask();
    background: rgba(255, 255, 255, 0.4);

    .loader {
        > img {
            @include lib-loading-mask();
        }

        > p {
            display: none;
        }
    }
}

body {
    > .loading-mask {
        z-index: $loader-overlay__z-index;
    }
}

._block-content-loading {
    position: relative;
}
