//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    z-index: 6;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        position: relative;
    }
}

.logo {
    padding: 8px 10px 8px 15px;
    margin-left: 60px;
    position: relative;
    z-index: 5;
    font-size: 0;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.header-contact {
    a {
        color: #fff;
        font-weight: bold;
    }
}

#search {
    border-color: #333;
    background-color: #333;
    color: #fff;
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.customer.dropdown {
    @include lib-dropdown(
        $_toggle-selector: ".customer-toggle",
        $_options-selector: ".customer-dropdown",
        $_dropdown-toggle-icon-content: $icon-account,
        $_dropdown-toggle-active-icon-content: $icon-account,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_icon-font-position: before,
        $_icon-font-size: 35px,
        $_icon-font-line-height: 33px,
        $_icon-font-color: $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-color-active: $header-icons-color
    );
    color: $color-white;
    margin: 0 0 0 $indent__s;
    .customer-toggle {
        > span {
            display: none;
        }
    }
}

$mobile-header-padding: 1rem;
$desktop-header-padding: 1rem;

.mobile-header-top-row {
    padding-top: $desktop-header-padding;
    padding-bottom: $desktop-header-padding;
    @media (max-width: 768px) {
        padding-top: $mobile-header-padding;
        padding-bottom: $mobile-header-padding;
    }
}

.mobile-header-bottom-row {
    padding-top: $desktop-header-padding;
    padding-bottom: $desktop-header-padding;
    @media (max-width: 768px) {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: $mobile-header-padding;
        padding-bottom: $mobile-header-padding;
    }
}

//
//  Business USPs
//  ---------------------------------------------

.usp-container {
    background: #333;
    color: #fff;
    padding: 0.8rem 2rem;
    //border-top: 1px solid $panel__divider-color;
    box-shadow: $panel-shadow;
}

#business-usps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    > li {
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
    }
    .reviewsio-word {
        display: flex;
        align-items: center;
        justify-content: center;
        @include max-screen($screen__s) {
            flex-direction: column;
            gap: 3px;
        }
    }
}

.subnav-message-container {
    background: $navigation__background;
    color: $color-ba-green;
    padding: 8px 20px;
    text-align: center;
    p {
        margin: 0;
    }
}

$curve-adornment-height: 20px;

.curve-container {
    position: relative;
    overflow: hidden;
    height: $curve-adornment-height;
    display: flex;
    justify-content: center;
}

$header-height: 400px;

.content-header {
    position: relative;
    overflow: hidden;
    align-items: center;
    background-color: #000;
    .content-header_content {
        color: #fff;
        position: relative;
        z-index: 3;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .page-title {
        color: inherit;
        text-align: center;
    }
    .page-subtitle {
        color: $primary__color__text;
        font-size: $font-size__l;
        font-weight: $font-weight__light;
        margin: 0;
        text-align: center;
    }
    .content-header_image {
        @include position-absolute-fill();
        z-index: 1;
        overflow: hidden;
        height: 100%;
        .image-container {
            height: 100%;
            @media(max-width: 767px) {
                width: 0;
                height: 0;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content-header_overlay {
        @include position-absolute-fill();
        opacity: 0.65;
        z-index: 2;
    }
    .content-header_description {
        color: inherit;
        margin-bottom: 0;
        text-align: center;
        text-shadow: 0 0 10px #000;
    }
    .breadcrumbs {
        width: 100%;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding: 0;
        margin: 0 0 1rem 0;
        z-index: 1;
        .items {
            color: #fff;
        }
    }
    @include max-screen($screen__l) {
        .content-header_content {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
    }
    @include max-screen($screen__m) {
        grid-template-columns: 1fr;
        .content-header_content {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
        .content-header_content-wrapper {
            width: unset;
        }
        .content-header_image {
            img {
                height: unset;
            }
        }
    }
    &.home {
        .content-header_content {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
        .content-header_content-wrapper {
            width: 80%;
            margin: 0 auto;
        }
        @include max-screen($screen__m) {
            .content-header_content {
                padding-top: 6rem;
                padding-bottom: 6rem;
            }
            .content-header_content-wrapper {
                width: unset;
            }
        }
        @include max-screen($screen__s) {
            .content-header_content {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }
    }
    &.category {
        .content-header_content {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .breadcrumbs {
            min-height: 1em;
        }
        .page-title {
            margin-bottom: 3rem;
            //text-align: center;
        }
        .page-subtitle {
            margin-bottom: 7rem;
        }
    }
    &.product {
        background-color: transparent;
        .content-header_content {
            padding-top: 1rem;
            padding-bottom: 6rem;
            height: 100%;
            @include max-screen($screen__m) {
                padding-bottom: 1rem;
            }
        }
        .content-header_image {
            max-height: 14rem;
            img {
                filter: blur(10px);
            }
        }
        .breadcrumbs {
            min-height: 2rem;
        }
        @include max-screen($screen__m) {
            .content-header_image {
                display: none;
            }
            .breadcrumbs {
                margin: 0;
                .items {
                    color: $primary__color__text;
                }
            }
        }
    }
}

.cms-index-index {
    .content-header {
        .content-header_overlay {
            opacity: 0.35;
        }
    }
}

.cms-page-view, .checkout-cart-index {
    .page-title {
        margin-bottom: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        //padding: 0 8px;
    }

    .header {
        &.panel {
            display: none;

            > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            // @extend .abs-add-clearfix-desktop;
            // padding: ($indent__s + 6) 2rem;
            // > .customer.dropdown {
            //     float: right;
            // }
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            //border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: 0;
        font-size: 0;

        img {
            max-height: inherit;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .header {
        &.content {
            > .customer.dropdown {
                display: none;
            }
        }
    }

    .usp-container {
        padding: 8px 4px;
    }
}
