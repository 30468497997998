.mpblog-post-view,
.mpblog-post-index,
.mpblog-category-view,
.mpblog-month-view,
.mpblog-tag-view,
.mpblog-topic-view {
  .page-title {
    margin-bottom: 0;
  }

  .content-header .content-header_content {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.mp-blog-rss {
  text-align: right;
}

.post-list-content {
  display: grid;
  gap: 2rem;

  .content-image-stack {
    .image {
      width: 320px;
      height: 320px;

      &.widget {
        width: 260px;
        height: 260px;
      }

      @include max-screen($screen__s) {
        width: 100%;

        &.widget {
          width: 100%;
        }
      }

      &.placeholder {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          background-color: $color-ba-green;

          // background: -webkit-linear-gradient(-20deg, $color-ba-green, $color-ss-pink);
          img {
            width: 65%;
            height: 65%;
            filter: saturate(0%) brightness(10);

            @include max-screen($screen__s) {
              width: 40%;
              height: unset;
            }
          }
        }
      }
    }

    .post-short-description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.post-view-image {
  img {
    border-radius: $panel-border-radius;
  }
}

.post-post_content {
  p {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  img {
    border-radius: $panel-border-radius;
  }
}

.menu-categories {
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.menu-archives {
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.post-categories {
  margin-bottom: 2rem;
}

.n2-panel.post-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  @include max-screen($screen__s) {
    display: grid;
  }

  .post-info-list {
    display: flex;
    align-items: center;

    @include max-screen($screen__s) {
      display: grid;

      .post-info-divider {
        display: none;
      }
    }
  }
}