//
//  Typography
//  _____________________________________________

@mixin lib-font-face(
    $family-name,
    $font-path,
    $font-weight: normal,
    $font-style: normal
) {
    @font-face {
        font-family: $family-name;
        src: url("#{$font-path}.eot");
        src: url("#{$font-path}.eot?#iefix") format("embedded-opentype"),
            url("#{$font-path}.woff2") format("woff2"),
            url("#{$font-path}.woff") format("woff"),
            url("#{$font-path}.ttf") format("truetype"),
            url("#{$font-path}.svg##{$family-name}") format("svg");
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}

//  Rem font size
@mixin lib-font-size($sizeValue) {
    @if type-of($sizeValue) ==
        number and
        unit($sizeValue) !=
        "%" and
        $sizeValue !=
        false
    {
        @if unit($sizeValue) == $font-size-unit or unit($sizeValue) == "em" {
            @include lib-css(font-size, $sizeValue);
        } @else {
            @include lib-css(font-size, lib-font-size-value($sizeValue));
        }
    }
}

//  Rem line height
@mixin lib-line-height($heightValue) {
    @if $heightValue != false {
        @if unit($heightValue) == "%" {
            @include lib-css(line-height, $heightValue);
        } @else {
            @include lib-css(line-height, lib-font-size-value($heightValue));
        }
    }
}

@mixin lib-wrap-words() {
    @include lib-hyphens();
    word-wrap: break-word;
}

@mixin lib-text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin lib-text-hide() {
    background-color: transparent;
    border: 0;
    color: transparent;
    font: "0px/0" a;
    text-shadow: none;
}

//  Optional hyphenation
@mixin lib-hyphens($mode: auto) {
    word-wrap: break-word;
    hyphens: $mode;
}

@mixin lib-typography(
    $_font-size: $font-size__base,
    $_color: $text__color,
    $_font-family: $font-family__base,
    $_font-weight: $font-weight__light,
    $_line-height: $line-height__base,
    $_font-style: $font-style__base
) {
    @include lib-css(color, $_color);
    @include lib-css(font-family, $_font-family);
    @include lib-css(font-style, $_font-style);
    @include lib-css(font-weight, $_font-weight);
    @include lib-css(line-height, $_line-height);
    @include lib-font-size($_font-size);
}

@mixin lib-list-reset-styles($_margin: 0, $_padding: 0) {
    @include lib-css(margin, $_margin);
    @include lib-css(padding, $_padding);
    list-style: none none;
}

@mixin lib-list-inline() {
    @include lib-list-reset-styles();
    & > li {
        display: inline-block;
        vertical-align: top;
    }
}

@mixin lib-link(
    $_link-color: $link__color,
    $_link-text-decoration: $link__text-decoration,
    $_link-color-visited: $link__visited__color,
    $_link-text-decoration-visited: $link__visited__text-decoration,
    $_link-color-hover: $link__hover__color,
    $_link-text-decoration-hover: $link__hover__text-decoration,
    $_link-text-decoration-color-hover: $link__hover__text-decoration-color,
    $_link-color-active: $link__active__color,
    $_link-text-decoration-active: $link__active__text-decoration,
    $_link-text-decoration-color-active: $link__active__text-decoration-color
) {
    @include lib-css(color, $_link-color);
    @include lib-css(text-decoration, $_link-text-decoration);

    // &:visited {
    //     @include lib-css(color, $_link-color-visited);
    //     @include lib-css(text-decoration, $_link-text-decoration-visited);
    // }

    &:hover {
        @include lib-css(color, $_link-color-hover);
        @include lib-css(text-decoration, $_link-text-decoration-hover);
        @include lib-css(
            text-decoration-color,
            $_link-text-decoration-color-hover
        );
    }

    &:active {
        @include lib-css(color, $_link-color-active);
        @include lib-css(text-decoration, $_link-text-decoration-active);
        @include lib-css(
            text-decoration-color,
            $_link-text-decoration-color-active
        );
    }
}

@mixin lib-heading($_heading_level: h1) {
    $h1: (
        color: $h1__font-color,
        font-size: $h1__font-size,
        font-family: $h1__font-family,
        font-weight: $h1__font-weight,
        line-height: $h1__line-height,
        font-style: $h1__font-style,
        margin-top: $h1__margin-top,
        margin-bottom: $h1__margin-bottom,
    );

    $h2: (
        color: $h2__font-color,
        font-size: $h2__font-size,
        font-family: $h2__font-family,
        font-weight: $h2__font-weight,
        line-height: $h2__line-height,
        font-style: $h2__font-style,
        margin-top: $h2__margin-top,
        margin-bottom: $h2__margin-bottom,
    );

    $h3: (
        color: $h3__font-color,
        font-size: $h3__font-size,
        font-family: $h3__font-family,
        font-weight: $h3__font-weight,
        line-height: $h3__line-height,
        font-style: $h3__font-style,
        margin-top: $h3__margin-top,
        margin-bottom: $h3__margin-bottom,
    );

    $h4: (
        color: $h4__font-color,
        font-size: $h4__font-size,
        font-family: $h4__font-family,
        font-weight: $h4__font-weight,
        line-height: $h4__line-height,
        font-style: $h4__font-style,
        margin-top: $h4__margin-top,
        margin-bottom: $h4__margin-bottom,
    );

    $h5: (
        color: $h5__font-color,
        font-size: $h5__font-size,
        font-family: $h5__font-family,
        font-weight: $h5__font-weight,
        line-height: $h5__line-height,
        font-style: $h5__font-style,
        margin-top: $h5__margin-top,
        margin-bottom: $h5__margin-bottom,
    );

    $h6: (
        color: $h6__font-color,
        font-size: $h6__font-size,
        font-family: $h6__font-family,
        font-weight: $h6__font-weight,
        line-height: $h6__line-height,
        font-style: $h6__font-style,
        margin-top: $h6__margin-top,
        margin-bottom: $h6__margin-bottom,
    );

    $map: $h1;
    @if $_heading_level == h2 {
        $map: $h2;
    } @else if $_heading_level == h3 {
        $map: $h3;
    } @else if $_heading_level == h4 {
        $map: $h4;
    } @else if $_heading_level == h5 {
        $map: $h5;
    } @else if $_heading_level == h6 {
        $map: $h6;
    }

    $_font-color: map-get($map, color);
    $_font-size: map-get($map, font-size);
    $_font-family: map-get($map, font-family);
    $_font-weight: map-get($map, font-weight);
    $_line-height: map-get($map, line-height);
    $_font-style: map-get($map, font-style);
    $_margin-top: map-get($map, margin-top);
    $_margin-bottom: map-get($map, margin-bottom);

    @include lib-typography(
        $_font-size,
        $_font-color,
        $_font-family,
        $_font-weight,
        $_line-height,
        $_font-style
    );

    @include lib-css(margin-top, lib-font-size-value($_margin-top));
    @include lib-css(margin-bottom, lib-font-size-value($_margin-bottom));
}

//
//  Base typography
//  ---------------------------------------------

@mixin lib-typography__base(
    $_abbr-border-color: $abbr__border-color,
    $_dfn-font-style: $font-style__emphasis,
    $_emphasis-font-style: $font-style__emphasis,
    $_hr-border-color: $hr__border-color,
    $_hr-border-style: $hr__border-style,
    $_hr-border-width: $hr__border-width,
    $_hr-margin-bottom: $line-height__computed,
    $_hr-margin-top: $line-height__computed,
    $_mark-background-color: $mark__background-color,
    $_mark-color: $mark__color,
    $_p-margin-bottom: $p__margin-bottom,
    $_p-margin-top: $p__margin-top,
    $_root-font-size: $root__font-size,
    $_small-font-size: $font-size__s,
    $_strong-font-weight: $font-weight__bold,
    $_sub-sup-font-size: 75%
) {
    html {
        @include lib-css(font-size, $_root-font-size);
        font-size-adjust: 100%;
    }

    body {
        @include lib-typography();
    }

    p {
        & {
            @include lib-css(margin-top, lib-font-size-value($_p-margin-top));
        }

        & {
            @include lib-css(
                margin-bottom,
                lib-font-size-value($_p-margin-bottom)
            );
        }
    }

    //  Abbreviations and acronyms
    abbr[title] {
        @include lib-css(border-bottom, 1px dotted $_abbr-border-color);
        cursor: help;
    }

    b,
    strong {
        @include lib-css(font-weight, $_strong-font-weight);
    }

    em,
    i {
        @include lib-css(font-style, $_emphasis-font-style);
    }

    mark {
        @include lib-css(background, $_mark-background-color);
        @include lib-css(color, $_mark-color);
    }

    small,
    .small {
        @include lib-css(font-size, $_small-font-size);
    }

    hr {
        border: 0;
        @include lib-css(
            border-top,
            $_hr-border-width $_hr-border-style $_hr-border-color
        );
        @include lib-css(margin-bottom, $_hr-margin-bottom);
        @include lib-css(margin-top, $_hr-margin-top);
    }

    sub,
    sup {
        @include lib-css(font-size, $_sub-sup-font-size);
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    sup {
        top: -0.5em;
    }

    sub {
        bottom: -0.25em;
    }

    dfn {
        @include lib-css(font-style, $_dfn-font-style);
    }
}

//
//  Headings
//  ---------------------------------------------

@mixin lib-typography-headings(
    $_heading-small-color: $heading__small-color,
    $_heading-small-line-height: $heading__small-line-height,
    $_heading-small-size: $heading__small-size
) {
    h1 {
        @include lib-heading(h1);
    }

    h2 {
        @include lib-heading(h2);
    }

    h3 {
        @include lib-heading(h3);
    }

    h4 {
        @include lib-heading(h4);
    }

    h5 {
        @include lib-heading(h5);
    }

    h6 {
        @include lib-heading(h6);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        small,
        .small {
            @include lib-typography(
                $_heading-small-size,
                $_heading-small-color,
                $_line-height: $_heading-small-line-height
            );
        }
        a {
            color: inherit;
        }
    }
}

//
//  Links
//  ---------------------------------------------

@mixin lib-typography-links() {
    a,
    .alink {
        @include lib-link();
    }
}

//
//  Unordered and Ordered lists
//  ---------------------------------------------

@mixin lib-typography-lists(
    $_list-margin-bottom: $list__margin-bottom,
    $_list-margin-top: $list__margin-top,
    $_list-item-margin-bottom: $list-item__margin-bottom,
    $_list-item-margin-top: $list-item__margin-top,
    $_dl-margin-bottom: $dl__margin-bottom,
    $_dl-margin-top: $dl__margin-top,
    $_dd-margin-bottom: $dd__margin-bottom,
    $_dd-margin-top: $dd__margin-top,
    $_dt-font-weight: $dt__font-weight,
    $_dt-margin-bottom: $dt__margin-bottom,
    $_dt-margin-top: $dt__margin-top
) {
    ul,
    ol {
        @include lib-css(margin-top, lib-font-size-value($_list-margin-top));
        @include lib-css(
            margin-bottom,
            lib-font-size-value($_list-margin-bottom)
        );

        & > li {
            @include lib-css(
                margin-top,
                lib-font-size-value($_list-item-margin-top)
            );
            @include lib-css(
                margin-bottom,
                lib-font-size-value($_list-item-margin-bottom)
            );
        }

        ul,
        ol {
            margin-bottom: 0;
        }
    }

    dl {
        @include lib-css(margin-bottom, $_dl-margin-bottom);
        @include lib-css(margin-top, $_dl-margin-top);
    }

    dt {
        @include lib-css(font-weight, $_dt-font-weight);
        @include lib-css(margin-bottom, $_dt-margin-bottom);
        @include lib-css(margin-top, $_dt-margin-top);
    }

    dd {
        @include lib-css(margin-bottom, $_dd-margin-bottom);
        @include lib-css(margin-top, $_dd-margin-top);
        margin-left: 0;
    }
}

//
//  All typography
//  ---------------------------------------------

@mixin lib-typography-all() {
    @include lib-typography__base();
    @include lib-typography-headings();
    @include lib-typography-links();
    @include lib-typography-lists();
}
